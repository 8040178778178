export const allProducts = [
  `10" Orbitz Floor Machine`,
  `20" Orbitz Floor Machine`,
  `S17 Disk Scrubber`,
  `S17 Disk Scrubber - Pad Assist`,
  `S20 Disk Scrubber`,
  `S20 Disk Scrubber - Pad Assist`,
  `S24 Cylindtical Scrubber`,
  `S20 Orbital Scrubber`,
  `M20 Disk Scrubber`,
  `M26 Disk Scrubber`,
  `M28 Disk Scrubber`,
  `M26 Cylindrical Scrubber`,
  `M24 Orbital Scrubber`,
  `M28 Orbital Scrubber`,
]
export const allStates = [
  `Alabama`,
  `Alaska`,
  `American Samoa`,
  `Arizona`,
  `Arkansas`,
  `California`,
  `Colorado`,
  `Connecticut`,
  `Delaware`,
  `District of Columbia`,
  `Federated States of Micronesia`,
  `Florida`,
  `Georgia`,
  `Guam`,
  `Hawaii`,
  `Idaho`,
  `Illinois`,
  `Indiana`,
  `Iowa`,
  `Kansas`,
  `Kentucky`,
  `Louisiana`,
  `Maine`,
  `Marshall Islands`,
  `Maryland`,
  `Massachusetts`,
  `Michigan`,
  `Minnesota`,
  `Mississippi`,
  `Missouri`,
  `Montana`,
  `Nebraska`,
  `Nevada`,
  `New Hampshire`,
  `New Jersey`,
  `New Mexico`,
  `New York`,
  `North Carolina`,
  `North Dakota`,
  `Northern Mariana Islands`,
  `Ohio`,
  `Oklahoma`,
  `Oregon`,
  `Palau`,
  `Pennsylvania`,
  `Puerto Rico`,
  `Rhode Island`,
  `South Carolina`,
  `South Dakota`,
  `Tennessee`,
  `Texas`,
  `Utah`,
  `Vermont`,
  `Virgin Island`,
  `Virginia`,
  `Washington`,
  `West Virginia`,
  `Wisconsin`,
  `Wyoming`,
]
export const allCountries = [
  `United States`,
  `Afghanistan`,
  `Albania`,
  `Algeria`,
  `Andorra`,
  `Angola`,
  `Anguilla`,
  `Antigua &amp; Barbuda`,
  `Argentina`,
  `Armenia`,
  `Aruba`,
  `Australia`,
  `Austria`,
  `Azerbaijan`,
  `Bahamas`,
  `Bahrain`,
  `Bangladesh`,
  `Barbados`,
  `Belarus`,
  `Belgium`,
  `Belize`,
  `Benin`,
  `Bermuda`,
  `Bhutan`,
  `Bolivia`,
  `Bosnia &amp; Herzegovina`,
  `Botswana`,
  `Brazil`,
  `British Virgin Islands`,
  `Brunei`,
  `Bulgaria`,
  `Burkina Faso`,
  `Burundi`,
  `Cambodia`,
  `Cameroon`,
  `Cape Verde`,
  `Cayman Islands`,
  `Chad`,
  `Chile`,
  `China`,
  `Colombia`,
  `Congo`,
  `Cook Islands`,
  `Costa Rica`,
  `Cote D Ivoire`,
  `Croatia`,
  `Cruise Ship`,
  `Cuba`,
  `Cyprus`,
  `Czech Republic`,
  `Denmark`,
  `Djibouti`,
  `Dominica`,
  `Dominican Republic`,
  `Ecuador`,
  `Egypt`,
  `El Salvador`,
  `Equatorial Guinea`,
  `Estonia`,
  `Ethiopia`,
  `Falkland Islands`,
  `Faroe Islands`,
  `Fiji`,
  `Finland`,
  `France`,
  `French Polynesia`,
  `French West Indies`,
  `Gabon`,
  `Gambia`,
  `Georgia`,
  `Germany`,
  `Ghana`,
  `Gibraltar`,
  `Greece`,
  `Greenland`,
  `Grenada`,
  `Guam`,
  `Guatemala`,
  `Guernsey`,
  `Guinea`,
  `Guinea Bissau`,
  `Guyana`,
  `Haiti`,
  `Honduras`,
  `Hong Kong`,
  `Hungary`,
  `Iceland`,
  `India`,
  `Indonesia`,
  `Iran`,
  `Iraq`,
  `Ireland`,
  `Isle of Man`,
  `Israel`,
  `Italy`,
  `Jamaica`,
  `Japan`,
  `Jersey`,
  `Jordan`,
  `Kazakhstan`,
  `Kenya`,
  `Kuwait`,
  `Kyrgyz Republic`,
  `Laos`,
  `Latvia`,
  `Lebanon`,
  `Lesotho`,
  `Liberia`,
  `Libya`,
  `Liechtenstein`,
  `Lithuania`,
  `Luxembourg`,
  `Macau`,
  `Macedonia`,
  `Madagascar`,
  `Malawi`,
  `Malaysia`,
  `Maldives`,
  `Mali`,
  `Malta`,
  `Mauritania`,
  `Mauritius`,
  `Mexico`,
  `Moldova`,
  `Monaco`,
  `Mongolia`,
  `Montenegro`,
  `Montserrat`,
  `Morocco`,
  `Mozambique`,
  `Namibia`,
  `Nepal`,
  `Netherlands`,
  `Netherlands Antilles`,
  `New Caledonia`,
  `New Zealand`,
  `Nicaragua`,
  `Niger`,
  `Nigeria`,
  `Norway`,
  `Oman`,
  `Pakistan`,
  `Palestine`,
  `Panama`,
  `Papua New Guinea`,
  `Paraguay`,
  `Peru`,
  `Philippines`,
  `Poland`,
  `Portugal`,
  `Puerto Rico`,
  `Qatar`,
  `Reunion`,
  `Romania`,
  `Russia`,
  `Rwanda`,
  `Saint Pierre &amp; Miquelon`,
  `Samoa`,
  `San Marino`,
  `Satellite`,
  `Saudi Arabia`,
  `Senegal`,
  `Serbia`,
  `Seychelles`,
  `Sierra Leone`,
  `Singapore`,
  `Slovakia`,
  `Slovenia`,
  `South Africa`,
  `South Korea`,
  `Spain`,
  `Sri Lanka`,
  `St Kitts &amp; Nevis`,
  `St Lucia`,
  `St Vincent`,
  `St. Lucia`,
  `Sudan`,
  `Suriname`,
  `Swaziland`,
  `Sweden`,
  `Switzerland`,
  `Syria`,
  `Taiwan`,
  `Tajikistan`,
  `Tanzania`,
  `Thailand`,
  `Timor L'Este`,
  `Togo`,
  `Tonga`,
  `Trinidad &amp; Tobago`,
  `Tunisia`,
  `Turkey`,
  `Turkmenistan`,
  `Turks &amp; Caicos`,
  `Uganda`,
  `Ukraine`,
  `United Arab Emirates`,
  `United Kingdom`,
  `Uruguay`,
  `Uzbekistan`,
  `Venezuela`,
  `Vietnam`,
  `Virgin Islands (US)`,
  `Yemen`,
  `Zambia`,
  `Zimbabwe`,
]

export const allMonths = [
  `January`,
  `February`,
  `March`,
  `April`,
  `May`,
  `June`,
  `July`,
  `August`,
  `September`,
  `October`,
  `November`,
  `December`,
]

export const allDays = [
  `1`,
  `2`,
  `3`,
  `4`,
  `5`,
  `6`,
  `7`,
  `8`,
  `9`,
  `10`,
  `11`,
  `12`,
  `13`,
  `14`,
  `15`,
  `16`,
  `17`,
  `18`,
  `19`,
  `20`,
  `21`,
  `22`,
  `23`,
  `24`,
  `25`,
  `26`,
  `27`,
  `28`,
  `29`,
  `30`,
]

export const allYears = [
  `2000`,
  `2001`,
  `2002`,
  `2003`,
  `2004`,
  `2005`,
  `2006`,
  `2007`,
  `2008`,
  `2009`,
  `2010`,
  `2011`,
  `2012`,
  `2013`,
  `2014`,
  `2015`,
  `2016`,
  `2017`,
  `2018`,
  `2019`,
  `2020`,
  `2021`,
  `2022`,
]
