import React from "react"
import { IoClose } from "react-icons/io5"
export default function RegAlert(props) {
  console.log(props)
  return (
    <div className="reg-alert-container">
      <div className="reg-alert-header">
        <IoClose onClick={props.function} />
      </div>
      <div className="reg-response">
        <h1>
          Thanks! We have received your Machine Registration and will begin
          processing it.
        </h1>
        <p>
          You should have received an email with all the details you provided
          for your record.
        </p>
        <a
          className="btn-styled-link"
          href={`http://www.tomcatequip.com/machineRegistrationPrinter.html?serial=${props.serial}`}
          target="_blank"
          rel="noreferrer"
        >
          View Details
        </a>
        <button onClick={props.function}>Close</button>
      </div>
    </div>
  )
}
