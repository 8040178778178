import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import "./registration.scss"
// import MainLayout from "../components/MainLayout"
// import SEO from "../components/seo"
// import { Helmet } from "react-helmet"
import {
  allStates,
  allCountries,
  allMonths,
  allDays,
  allYears,
} from "../components/data/arrays"
import MainLayout from "../components/MainLayout"
import RegAlert from "../components/RegistrationAlert/RegAlert"
import SEO from "../components/seo"

const RegistrationPage = () => {
  const [formData, setFormData] = useState({
    endUserCompany: "",
    endUserContact: "",
    endUserJobDescription: "",
    endUserAddress: "",
    endUserCity: "",
    endUserState: "",
    endUserCountry: "",
    endUserZip: "",
    endUserPhone: "",
    endUserEmail: "",
    serial: "",
    model: "",
    terms: "",
    terms2: "",
    signature2: "",
    installingDealer: "",
    installBranch: "",
    masterDistributor: "",
    installMonth: "",
    installDay: "",
    installYear: "",
    installedBy: "",
    installedByEmail: "",
    signature: "",
  })
  const [timberLineProducts, setTimberLineProducts] = useState([])
  const [regAlertToggle, setRegAlertToggle] = useState(true)
  function testFormData(e) {
    e.preventDefault()
    setFormData({
      endUserCompany: "Lauer's Test Company",
      endUserContact: "Test Lauer",
      endUserJobDescription: "Lauer's Gross Floor",
      endUserAddress: "123 Test Street",
      endUserCity: "Knox",
      endUserState: "Pennsylvania",
      endUserCountry: "United States",
      endUserZip: "12345",
      endUserPhone: "123-456-7890",
      endUserEmail: "chris.lauer@rpscorporation.com",
      serial: "5N14197 ",
      model: `KF10R`,
      terms: "on",
      terms2: "on",
      signature2: "Lauer Signature",
      installingDealer: "Lauer Test Equipment Co.",
      installBranch: "Lauer Test Branch",
      masterDistributor: "Lauer Test Equipment Co.",
      installMonth: "12",
      installDay: `13`,
      installYear: `2006`,
      installedBy: "Lauer",
      installedByEmail: "chris.lauer@rpscorporation.com",
      signature: "christopher lauer",
    })
  }
  const [serverResponse, setServerResponse] = useState(null)
  const CORS_PROXY = "https://factorycat-proxy.herokuapp.com/?q="
  const modelNumberUrl =
    "https://www.tomcatequip.com/processRegistrations.php?action=getModels&brand=TIMBERLINE"

  useEffect(async () => {
    const response = await fetch(`${CORS_PROXY}${modelNumberUrl}`)
    response.json().then((response) => {
      setTimberLineProducts(response)
      console.log(response)
    })
    return () => {}
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const response = await fetch(
      `${CORS_PROXY}https://www.tomcatequip.com/processRegistrations.php?serial=${formData.serial}&` +
        new URLSearchParams({
          endUserCompany: formData.endUserCompany,
          endUserContact: formData.endUserContact,
          endUserJobDescription: formData.endUserJobDescription,
          endUserAddress: formData.endUserAddress,
          endUserCity: formData.endUserCity,
          endUserState: formData.endUserState,
          endUserCountry: formData.endUserCountry,
          endUserZip: formData.endUserZip,
          endUserPhone: formData.endUserPhone,
          endUserEmail: formData.endUserEmail,
          serial: formData.serial,
          model: formData.model,
          terms: formData.terms,
          terms2: formData.terms2,
          signature2: formData.signature2,
          installingDealer: formData.installingDealer,
          installBranch: formData.installBranch,
          masterDistributor: formData.masterDistributor,
          installMonth: formData.installMonth,
          installDay: formData.installDay,
          installYear: formData.installYear,
          installedBy: formData.installedBy,
          installedByEmail: formData.installedByEmail,
          signature: formData.signature,
        })
    )
    response.json().then((response) => {
      console.log(response)
      if (response.failure) {
        console.log("Failed")
        setServerResponse(response.serialNo)
      } else {
        console.log("Success")
        setServerResponse(response.success)
        setRegAlertToggle(true)
      }
    })
  }

  const handleChange = (event) => {
    const value = event.target.value
    setFormData({
      ...formData,
      [event.target.name]: value,
    })
    console.log(formData)
  }
  const toggleAlert = () => {
    setRegAlertToggle(!regAlertToggle)
    setFormData({
      endUserCompany: "",
      endUserContact: "",
      endUserJobDescription: "",
      endUserAddress: "",
      endUserCity: "",
      endUserState: "",
      endUserCountry: "",
      endUserZip: "",
      endUserPhone: "",
      endUserEmail: "",
      serial: "",
      model: ``,
      terms: "",
      terms2: "",
      signature2: "",
      installingDealer: "",
      installBranch: "",
      masterDistributor: "L",
      installMonth: "",
      installDay: ``,
      installYear: ``,
      installedBy: "",
      installedByEmail: "",
      signature: "",
    })
  }
  //   const handleSubmit = (event) => {
  //     event.preventDefault()
  //     console.log("form submitted")
  //   }

  return (
    <MainLayout>
      <Helmet>
        <title>Register</title>
        <description>Register timberline floor cleaner</description>
      </Helmet>
      <SEO title="Register" />
      {/* This is a quick fix needs to be coded properly */}
      {regAlertToggle ? (
        <div
          className={
            serverResponse
              ? `registration-alert-wrapper open`
              : `registration-alert-wrapper close`
          }
        >
          <RegAlert serial={formData.serial} function={toggleAlert} />
        </div>
      ) : (
        ""
      )}
      <div className="registration-form-wrapper">
        <form
          id="myForm"
          onSubmit={handleSubmit}
          name="timberline-registration-form"
        >
          <div className="one-columns">
            <h2>
              <strong>Product Registration</strong>
            </h2>
          </div>
          <div className="three-columns">
            <input
              value={formData.endUserCompany}
              placeholder="Company Name"
              onChange={handleChange}
              name="endUserCompany"
              required
            />
            <input
              placeholder="Contact Name"
              value={formData.endUserContact}
              onChange={handleChange}
              name="endUserContact"
              required
            />
            <input
              placeholder="Contact Title"
              value={formData.endUserJobDescription}
              name="endUserJobDescription"
              onChange={handleChange}
              required
            />
          </div>
          <div className="one-columns">
            <input
              placeholder="Address"
              value={formData.endUserAddress}
              name="endUserAddress"
              onChange={handleChange}
              required
            />
          </div>
          <div className="three-columns">
            <input
              placeholder="City"
              value={formData.endUserCity}
              name="endUserCity"
              onChange={handleChange}
              required
            />
            <select
              onChange={handleChange}
              value={formData.endUserState}
              name="endUserState"
              required
            >
              <option value="Choose">Choose a State</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
            <select
              onChange={handleChange}
              value={formData.endUserCountry}
              name="endUserCountry"
              required
            >
              <option>Choose Country</option>
              <option value="USA">United States</option>
              <option value="AFG">Afghanistan</option>
              <option value="ALA">Åland Islands</option>
              <option value="ALB">Albania</option>
              <option value="DZA">Algeria</option>
              <option value="ASM">American Samoa</option>
              <option value="AND">Andorra</option>
              <option value="AGO">Angola</option>
              <option value="AIA">Anguilla</option>
              <option value="ATA">Antarctica</option>
              <option value="ATG">Antigua and Barbuda</option>
              <option value="ARG">Argentina</option>
              <option value="ARM">Armenia</option>
              <option value="ABW">Aruba</option>
              <option value="AUS">Australia</option>
              <option value="AUT">Austria</option>
              <option value="AZE">Azerbaijan</option>
              <option value="BHS">Bahamas</option>
              <option value="BHR">Bahrain</option>
              <option value="BGD">Bangladesh</option>
              <option value="BRB">Barbados</option>
              <option value="BLR">Belarus</option>
              <option value="BEL">Belgium</option>
              <option value="BLZ">Belize</option>
              <option value="BEN">Benin</option>
              <option value="BMU">Bermuda</option>
              <option value="BTN">Bhutan</option>
              <option value="BOL">Bolivia, Plurinational State of</option>
              <option value="BES">Bonaire, Sint Eustatius and Saba</option>
              <option value="BIH">Bosnia and Herzegovina</option>
              <option value="BWA">Botswana</option>
              <option value="BVT">Bouvet Island</option>
              <option value="BRA">Brazil</option>
              <option value="IOT">British Indian Ocean Territory</option>
              <option value="BRN">Brunei Darussalam</option>
              <option value="BGR">Bulgaria</option>
              <option value="BFA">Burkina Faso</option>
              <option value="BDI">Burundi</option>
              <option value="KHM">Cambodia</option>
              <option value="CMR">Cameroon</option>
              <option value="CAN">Canada</option>
              <option value="CPV">Cape Verde</option>
              <option value="CYM">Cayman Islands</option>
              <option value="CAF">Central African Republic</option>
              <option value="TCD">Chad</option>
              <option value="CHL">Chile</option>
              <option value="CHN">China</option>
              <option value="CXR">Christmas Island</option>
              <option value="CCK">Cocos (Keeling) Islands</option>
              <option value="COL">Colombia</option>
              <option value="COM">Comoros</option>
              <option value="COG">Congo</option>
              <option value="COD">Congo, the Democratic Republic of the</option>
              <option value="COK">Cook Islands</option>
              <option value="CRI">Costa Rica</option>
              <option value="CIV">Côte d'Ivoire</option>
              <option value="HRV">Croatia</option>
              <option value="CUB">Cuba</option>
              <option value="CUW">Curaçao</option>
              <option value="CYP">Cyprus</option>
              <option value="CZE">Czech Republic</option>
              <option value="DNK">Denmark</option>
              <option value="DJI">Djibouti</option>
              <option value="DMA">Dominica</option>
              <option value="DOM">Dominican Republic</option>
              <option value="ECU">Ecuador</option>
              <option value="EGY">Egypt</option>
              <option value="SLV">El Salvador</option>
              <option value="GNQ">Equatorial Guinea</option>
              <option value="ERI">Eritrea</option>
              <option value="EST">Estonia</option>
              <option value="ETH">Ethiopia</option>
              <option value="FLK">Falkland Islands (Malvinas)</option>
              <option value="FRO">Faroe Islands</option>
              <option value="FJI">Fiji</option>
              <option value="FIN">Finland</option>
              <option value="FRA">France</option>
              <option value="GUF">French Guiana</option>
              <option value="PYF">French Polynesia</option>
              <option value="ATF">French Southern Territories</option>
              <option value="GAB">Gabon</option>
              <option value="GMB">Gambia</option>
              <option value="GEO">Georgia</option>
              <option value="DEU">Germany</option>
              <option value="GHA">Ghana</option>
              <option value="GIB">Gibraltar</option>
              <option value="GRC">Greece</option>
              <option value="GRL">Greenland</option>
              <option value="GRD">Grenada</option>
              <option value="GLP">Guadeloupe</option>
              <option value="GUM">Guam</option>
              <option value="GTM">Guatemala</option>
              <option value="GGY">Guernsey</option>
              <option value="GIN">Guinea</option>
              <option value="GNB">Guinea-Bissau</option>
              <option value="GUY">Guyana</option>
              <option value="HTI">Haiti</option>
              <option value="HMD">Heard Island and McDonald Islands</option>
              <option value="VAT">Holy See (Vatican City State)</option>
              <option value="HND">Honduras</option>
              <option value="HKG">Hong Kong</option>
              <option value="HUN">Hungary</option>
              <option value="ISL">Iceland</option>
              <option value="IND">India</option>
              <option value="IDN">Indonesia</option>
              <option value="IRN">Iran, Islamic Republic of</option>
              <option value="IRQ">Iraq</option>
              <option value="IRL">Ireland</option>
              <option value="IMN">Isle of Man</option>
              <option value="ISR">Israel</option>
              <option value="ITA">Italy</option>
              <option value="JAM">Jamaica</option>
              <option value="JPN">Japan</option>
              <option value="JEY">Jersey</option>
              <option value="JOR">Jordan</option>
              <option value="KAZ">Kazakhstan</option>
              <option value="KEN">Kenya</option>
              <option value="KIR">Kiribati</option>
              <option value="PRK">
                Korea, Democratic People's Republic of
              </option>
              <option value="KOR">Korea, Republic of</option>
              <option value="KWT">Kuwait</option>
              <option value="KGZ">Kyrgyzstan</option>
              <option value="LAO">Lao People's Democratic Republic</option>
              <option value="LVA">Latvia</option>
              <option value="LBN">Lebanon</option>
              <option value="LSO">Lesotho</option>
              <option value="LBR">Liberia</option>
              <option value="LBY">Libya</option>
              <option value="LIE">Liechtenstein</option>
              <option value="LTU">Lithuania</option>
              <option value="LUX">Luxembourg</option>
              <option value="MAC">Macao</option>
              <option value="MKD">
                Macedonia, the former Yugoslav Republic of
              </option>
              <option value="MDG">Madagascar</option>
              <option value="MWI">Malawi</option>
              <option value="MYS">Malaysia</option>
              <option value="MDV">Maldives</option>
              <option value="MLI">Mali</option>
              <option value="MLT">Malta</option>
              <option value="MHL">Marshall Islands</option>
              <option value="MTQ">Martinique</option>
              <option value="MRT">Mauritania</option>
              <option value="MUS">Mauritius</option>
              <option value="MYT">Mayotte</option>
              <option value="MEX">Mexico</option>
              <option value="FSM">Micronesia, Federated States of</option>
              <option value="MDA">Moldova, Republic of</option>
              <option value="MCO">Monaco</option>
              <option value="MNG">Mongolia</option>
              <option value="MNE">Montenegro</option>
              <option value="MSR">Montserrat</option>
              <option value="MAR">Morocco</option>
              <option value="MOZ">Mozambique</option>
              <option value="MMR">Myanmar</option>
              <option value="NAM">Namibia</option>
              <option value="NRU">Nauru</option>
              <option value="NPL">Nepal</option>
              <option value="NLD">Netherlands</option>
              <option value="NCL">New Caledonia</option>
              <option value="NZL">New Zealand</option>
              <option value="NIC">Nicaragua</option>
              <option value="NER">Niger</option>
              <option value="NGA">Nigeria</option>
              <option value="NIU">Niue</option>
              <option value="NFK">Norfolk Island</option>
              <option value="MNP">Northern Mariana Islands</option>
              <option value="NOR">Norway</option>
              <option value="OMN">Oman</option>
              <option value="PAK">Pakistan</option>
              <option value="PLW">Palau</option>
              <option value="PSE">Palestinian Territory, Occupied</option>
              <option value="PAN">Panama</option>
              <option value="PNG">Papua New Guinea</option>
              <option value="PRY">Paraguay</option>
              <option value="PER">Peru</option>
              <option value="PHL">Philippines</option>
              <option value="PCN">Pitcairn</option>
              <option value="POL">Poland</option>
              <option value="PRT">Portugal</option>
              <option value="PRI">Puerto Rico</option>
              <option value="QAT">Qatar</option>
              <option value="REU">Réunion</option>
              <option value="ROU">Romania</option>
              <option value="RUS">Russian Federation</option>
              <option value="RWA">Rwanda</option>
              <option value="BLM">Saint Barthélemy</option>
              <option value="SHN">
                Saint Helena, Ascension and Tristan da Cunha
              </option>
              <option value="KNA">Saint Kitts and Nevis</option>
              <option value="LCA">Saint Lucia</option>
              <option value="MAF">Saint Martin (French part)</option>
              <option value="SPM">Saint Pierre and Miquelon</option>
              <option value="VCT">Saint Vincent and the Grenadines</option>
              <option value="WSM">Samoa</option>
              <option value="SMR">San Marino</option>
              <option value="STP">Sao Tome and Principe</option>
              <option value="SAU">Saudi Arabia</option>
              <option value="SEN">Senegal</option>
              <option value="SRB">Serbia</option>
              <option value="SYC">Seychelles</option>
              <option value="SLE">Sierra Leone</option>
              <option value="SGP">Singapore</option>
              <option value="SXM">Sint Maarten (Dutch part)</option>
              <option value="SVK">Slovakia</option>
              <option value="SVN">Slovenia</option>
              <option value="SLB">Solomon Islands</option>
              <option value="SOM">Somalia</option>
              <option value="ZAF">South Africa</option>
              <option value="SGS">
                South Georgia and the South Sandwich Islands
              </option>
              <option value="SSD">South Sudan</option>
              <option value="ESP">Spain</option>
              <option value="LKA">Sri Lanka</option>
              <option value="SDN">Sudan</option>
              <option value="SUR">Suriname</option>
              <option value="SJM">Svalbard and Jan Mayen</option>
              <option value="SWZ">Swaziland</option>
              <option value="SWE">Sweden</option>
              <option value="CHE">Switzerland</option>
              <option value="SYR">Syrian Arab Republic</option>
              <option value="TWN">Taiwan, Province of China</option>
              <option value="TJK">Tajikistan</option>
              <option value="TZA">Tanzania, United Republic of</option>
              <option value="THA">Thailand</option>
              <option value="TLS">Timor-Leste</option>
              <option value="TGO">Togo</option>
              <option value="TKL">Tokelau</option>
              <option value="TON">Tonga</option>
              <option value="TTO">Trinidad and Tobago</option>
              <option value="TUN">Tunisia</option>
              <option value="TUR">Turkey</option>
              <option value="TKM">Turkmenistan</option>
              <option value="TCA">Turks and Caicos Islands</option>
              <option value="TUV">Tuvalu</option>
              <option value="UGA">Uganda</option>
              <option value="UKR">Ukraine</option>
              <option value="ARE">United Arab Emirates</option>
              <option value="GBR">United Kingdom</option>
              <option value="UMI">United States Minor Outlying Islands</option>
              <option value="URY">Uruguay</option>
              <option value="UZB">Uzbekistan</option>
              <option value="VUT">Vanuatu</option>
              <option value="VEN">Venezuela, Bolivarian Republic of</option>
              <option value="VNM">Viet Nam</option>
              <option value="VGB">Virgin Islands, British</option>
              <option value="VIR">Virgin Islands, U.S.</option>
              <option value="WLF">Wallis and Futuna</option>
              <option value="ESH">Western Sahara</option>
              <option value="YEM">Yemen</option>
              <option value="ZMB">Zambia</option>
              <option value="ZWE">Zimbabwe</option>
            </select>
          </div>
          <div className="three-columns">
            <input
              placeholder="Zip Code"
              onChange={handleChange}
              value={formData.endUserZip}
              name="endUserZip"
              required
            />
            <input
              placeholder="Phone"
              type="tel"
              onChange={handleChange}
              value={formData.endUserPhone}
              name="endUserPhone"
              required
            />
            <input
              placeholder="Email"
              onChange={handleChange}
              value={formData.endUserEmail}
              type="email"
              name="endUserEmail"
              required
            />
          </div>
          <div className="two-columns">
            <input
              placeholder="Serial Number"
              onChange={handleChange}
              value={formData.serial}
              name="serial"
              required
            />
            <select
              value={formData.model}
              name="model"
              onChange={handleChange}
              required
            >
              <option>Select Model</option>
              {timberLineProducts.map((product, index) => (
                <option value={product[0]} key={index}>
                  {product[1]}
                </option>
              ))}
            </select>
          </div>
          <div className="three-columns">
            <input
              placeholder="Installing Distributor"
              onChange={handleChange}
              value={formData.installingDealer}
              name="installingDealer"
              required
            />
            <input
              placeholder="Installing Distributor Branch"
              onChange={handleChange}
              value={formData.installBranch}
              name="installBranch"
              required
            />
            <input
              placeholder="Master Distributor"
              onChange={handleChange}
              value={formData.masterDistributor}
              name="masterDistributor"
            />
          </div>

          <label>Installation Date:</label>
          <div className="three-columns">
            <select
              onChange={handleChange}
              value={formData.installMonth}
              name="installMonth"
            >
              <option>Month</option>
              {allMonths.map((month, index) => (
                <option key={index} value={index + 1}>
                  {month}
                </option>
              ))}
            </select>
            <select
              onChange={handleChange}
              value={formData.installDay}
              name="installDay"
            >
              <option>Day</option>
              {allDays.map((day, index) => (
                <option key={index} value={day < 10 ? `0${day}` : day}>
                  {day}
                </option>
              ))}
            </select>
            <select
              onChange={handleChange}
              value={formData.installYear}
              name="installYear"
            >
              <option>Year</option>
              {allYears.map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          <div className="two-columns">
            <input
              placeholder="Installing By Name"
              onChange={handleChange}
              value={formData.installedBy}
              name="installedBy"
              required
            />
            <input
              placeholder="Installing By Email"
              onChange={handleChange}
              value={formData.installedByEmail}
              name="installedByEmail"
              type="email"
              required
            />
          </div>
          <div className="two-columns">
            <div>
              <strong>Terms of Acceptance</strong>
              <ul>
                <li>
                  I have received copies of the Operators and Parts manuals
                  applicable to this machine and understand that they contain
                  important information about the safe use and proper
                  maintenance of this machine.
                </li>
                <li>
                  I understand that all operators must read, understand and
                  follow the instructions in the Operators Manual, and agree
                  they will be trained in the safe use of this machine in
                  accordance with the warnings and instructions provided on the
                  machine and contained in the Operator’s Manual provided with
                  the machine prior to operating it.
                </li>
                <li>
                  I am authorized to make the foregoing acknowledgments on
                  behalf of the End User indicated above and on behalf of my
                  company am authorized to submit this Warranty Registration.
                </li>
              </ul>
            </div>
            <div>
              <strong>End User Representative (Electronic Signature)*</strong>
              <input
                placeholder="User Signature"
                onChange={handleChange}
                value={formData.signature2}
                name="signature2"
                required
              />
              <div className="checkbox-container">
                <input
                  onChange={handleChange}
                  type="checkbox"
                  name="terms"
                  checked={formData.terms}
                  required
                />
                <p>
                  I understand that checking this box constitutes a legal
                  signature confirming that I acknowledge and agree to the above
                  Terms of Acceptance.
                </p>
              </div>

              <strong>Installed By (Electronic Signature)*</strong>
              <input
                placeholder="Installer Signature"
                onChange={handleChange}
                value={formData.signature}
                name="signature"
                required
              />
              <div className="checkbox-container">
                <input
                  onChange={handleChange}
                  type="checkbox"
                  checked={formData.terms2}
                  name="terms2"
                  required
                />
                <p>
                  I understand I understand that checking this box consitutes a
                  legal signature confirming that I installed the piece of
                  equipment with the End User indicated above and provided them
                  training and manuals.
                </p>
              </div>
            </div>
          </div>
          <button type="submit">Register</button>
        </form>
      </div>
    </MainLayout>
  )
}

export default RegistrationPage
